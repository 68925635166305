import React, { useState, useEffect, useCallback } from 'react';
import NotificationManager from './NotificationManager';
import Notifications from './Notifications';

type NotificationContainerProps = {
  enterTimeout?: number;
  leaveTimeout?: number;
};

const NotificationContainer = ({
  enterTimeout = 400,
  leaveTimeout = 400,
}: NotificationContainerProps) => {
  const [notifications, setNotifications] = useState([]);

  const handleStoreChange = useCallback((updatedNotifications) => {
    setNotifications(updatedNotifications);
  }, []);

  useEffect(() => {
    NotificationManager.addChangeListener(handleStoreChange);

    return () => {
      NotificationManager.removeChangeListener(handleStoreChange);
    };
  }, [handleStoreChange]);

  const handleRequestHide = useCallback((id) => {
    NotificationManager.remove(id);
  }, []);

  return (
    <Notifications
      enterTimeout={enterTimeout}
      leaveTimeout={leaveTimeout}
      notifications={notifications}
      onRequestHide={handleRequestHide}
    />
  );
};

export default NotificationContainer;
