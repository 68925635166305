import React, { useRef, useEffect, useCallback } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { StyledCloseButton, StyledNotification } from './notifications.styles';

type NotificationProps = {
  timeOut: number;
  onClick: () => void;
  onRequestHide: () => void;
  type: string;
  message: string;
  title: string;
  closeButton: boolean;
};

const Notification = ({
  timeOut = 5000,
  onClick = () => {},
  onRequestHide = () => {},
  type = 'info',
  message = null,
  title = null,
  closeButton = false,
}: NotificationProps) => {
  const timer = useRef(null);
  const containerRef = useRef(null);

  const requestHide = useCallback(() => {
    if (onRequestHide) {
      onRequestHide();
    }
  }, [onRequestHide]);

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
    requestHide();
  }, [onClick, requestHide]);

  useEffect(() => {
    let timerRef = timer.current;

    if (timeOut !== 0) {
      timerRef = setTimeout(requestHide, timeOut);
    }

    return () => {
      if (timerRef) {
        clearTimeout(timerRef);
      }
    };
  }, [timeOut, requestHide]);

  useEffect(() => {
    const ref = containerRef?.current;

    if (ref) {
      ref.addEventListener('click', handleClick);
    }

    return () => {
      ref.removeEventListener('click', handleClick);
    };
  }, [handleClick]);

  const handleCloseButtonClick = useCallback(
    (e) => {
      e.stopPropagation();
      onRequestHide();
    },
    [onRequestHide]
  );

  return (
    <StyledNotification className={`notification notification-${type}`}>
      <div ref={containerRef} className="notification-message" role="alert">
        {title && <h4 className="title">{title}</h4>}
        <div className="message">{message}</div>
      </div>
      {closeButton && (
        <StyledCloseButton onClick={handleCloseButtonClick}>
          <CloseIcon />
        </StyledCloseButton>
      )}
    </StyledNotification>
  );
};

export default Notification;
