import { apiVersion } from '~utils/platform';
import logger from '~utils/logger';
import { httpOrigin } from '../configs/apiOrigin';
import popoutComponentName from '../utils/popoutComponentName';

const apiUrlOrigin = httpOrigin(`/trader-rest-service/${apiVersion()}/`);

/**
 *
 * @param {Object} data
 * @param {String} data.token - user token
 * @param {Number} data.ping - latency
 * @param {String} data.platform - window platform
 * @returns {Boolean}
 */

type setActiveStatusUserParams = {
  ping: number;
  token: string;
  platform: string;
};

const setActiveStatusUser = async ({
  ping,
  token,
  platform,
}: setActiveStatusUserParams): Promise<boolean> => {
  if (popoutComponentName()) {
    return true;
  }

  try {
    const res = await fetch(`${apiUrlOrigin}active_user`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify({
        ping,
        platform,
        status: true,
      }),
    });
    const json = await res.json();

    if (!res.ok && res.status !== 200) {
      throw new Error(`active_user: ${json?.error || json?.message}`);
    }

    return json;
  } catch (e) {
    logger.logCriticalError(e, {
      isLogIfHidden: false,
    });
    return false;
  }
};

export default setActiveStatusUser;
