import splitPrice, { splitPriceCOP } from './splitPrice';
import decimalPlaces from './decimalPlaces';

export function formatNumberToCommas(num: number | string): string {
  // 1000  to 1,000
  const number = Number(num);
  const str = number.toString().split('.');
  str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return str.join('.');
}

/**
 * Adds zeroes to a number if you enter K, M or G keys
 * @param {string} value - new value to add zeroes
 * @param {string} oldValue - old value that was already correct
 * @return {string} new_or_old - value formatted correctly
 */
export function formatNumberAbrToNumber(
  value: string,
  oldValue: string
): string {
  let oldValueNum = Number(oldValue);

  // 1k to 1000
  if (Number.isNaN(oldValueNum)) oldValueNum = 0;
  if (Number.isNaN(Number(value))) {
    try {
      if (value.toLowerCase().includes('k')) {
        return oldValueNum === 0
          ? '1000'
          : Math.round(oldValueNum * 1000).toString();
      }

      if (value.toLowerCase().includes('m')) {
        return oldValueNum === 0
          ? '1000000'
          : Math.round(oldValueNum * 1000000).toString();
        // } else if (value.includes('g') || value.includes('G')) {
        // 	return Number(oldValue) == 0 ? '1000000000' : (Number(oldValue) * 1000000000).toString();
      }

      return oldValue;
    } catch (e) {
      return '0';
    }
  } else {
    return value;
  }
}

/**
 * Changes format of a number to abreviations. Example: 1,000 to 1K
 * @param {number/string} volume
 * @return {string} value - formatted to two decimals
 */
export function formatNumberToAbr(volume: number | string): string {
  // 1000 to 1K
  const digits = 1;
  let num = Number(volume);

  if (Number.isNaN(num)) num = 0;
  if (num >= 1000000000) {
    return `${(num / 1000000000).toFixed(digits).replace(/\.0$/, '')}G`;
  }
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(digits).replace(/\.0$/, '')}M`;
  }
  if (num >= 1000) {
    return `${(num / 1000).toFixed(digits).replace(/\.0$/, '')}K`;
  }
  return num.toString();
}

/**
 * Evaluates the correct decimal presicion acording to symbol
 * @param {string} priceString
 * @param {string} symbol
 */
export function inputConstraintRestriction(
  priceString: string,
  symbol: string
): string {
  let resultValue = priceString;
  const spPrice = priceString.toString().split('.');
  if (spPrice.length > 1) {
    switch (symbol) {
      case 'USD/JPY':
        resultValue = `${spPrice[0]}.${spPrice[1].substring(0, 3)}`;
        break;
      default:
        break;
    }
  }
  return resultValue;
}

/**
 * Evaluate the correct position of the decimals
 * @param {Number | String} priceString
 * @param {String} symbol
 * @param {Boolean} roundUp - should it be ceiled?
 * @return {Array} value - array of the input number
 */
export function specificRule(
  priceString: string,
  symbol: string,
  roundUp: boolean = false,
  instrumentId: number = 0
): string[] {
  let priceTmp: string[] = [];
  const indexPoint = `${priceString}`.indexOf('.');

  if (indexPoint !== -1) {
    const integer = priceString.substring(0, indexPoint);
    const decimals = priceString.substring(indexPoint + 1);
    let arrayDecimals = decimals.split('');
    let appliedRule = false;
    const [
      arrayDecimals0 = '',
      arrayDecimals1 = '',
      arrayDecimals2 = '',
      arrayDecimals3 = '',
    ] = arrayDecimals;

    switch (symbol) {
      case 'USD/PEN':
      case 'USD/BRL':
        priceTmp[0] = `${integer}.${arrayDecimals0}${arrayDecimals1}`;
        priceTmp[1] = `${arrayDecimals2}${arrayDecimals3}`;
        arrayDecimals = arrayDecimals.slice(3);
        break;
      case 'USD/CZK':
        priceTmp[0] = `${integer}.${arrayDecimals0}`;
        priceTmp[1] = `${arrayDecimals1}${arrayDecimals2}`;
        arrayDecimals = arrayDecimals.slice(3);
        appliedRule = true;
        break;
      case 'EUR/CZK':
        priceTmp[0] = `${integer}.${arrayDecimals0}`;
        priceTmp[1] = `${arrayDecimals1}${arrayDecimals2}`;
        arrayDecimals = arrayDecimals.slice(3);
        appliedRule = true;
        break;
      case 'USD/TWD':
        priceTmp[0] = `${integer}.${arrayDecimals0}`;
        priceTmp[1] = `${arrayDecimals1}${arrayDecimals2}`;
        arrayDecimals = arrayDecimals.slice(3);
        appliedRule = true;
        break;
      case 'USD/INR':
        priceTmp[0] = `${integer}.`;
        priceTmp[1] = `${arrayDecimals0}${arrayDecimals1}`;
        priceTmp[2] = `${arrayDecimals2}${arrayDecimals3}`;
        break;
      case 'USD/IDR':
        priceTmp[0] = integer.slice(0, -2);
        priceTmp[1] = integer.slice(-2);
        priceTmp[2] = `.${arrayDecimals0}${arrayDecimals1}`;
        break;
      case 'USD/PHP':
        if (roundUp) {
          let roundUpPrice = `${Math.ceil(Number(priceString) * 1000) / 1000}`;
          let decimalPos = roundUpPrice.indexOf('.');

          // round to integer 51.9991 =>  52
          if (decimalPos === -1) {
            roundUpPrice = `${roundUpPrice}.000`;
          }
          decimalPos = roundUpPrice.indexOf('.');
          const [decimals0 = '0', decimals1 = '0', decimals2 = '0'] =
            roundUpPrice.substring(decimalPos + 1).split('');

          priceTmp[0] = `${roundUpPrice.substring(0, decimalPos)}.`;
          priceTmp[1] = `${decimals0}${decimals1}`;
          priceTmp[2] = `${decimals2}`;
        } else {
          priceTmp[0] = `${integer}.`;
          priceTmp[1] = `${arrayDecimals0}${arrayDecimals1}`;
          arrayDecimals = arrayDecimals.slice(2);
          appliedRule = true;
        }
        break;
      case 'USD/KRW': {
        // https://visionfx.atlassian.net/browse/IPS-72
        if (window.APP_NAME === 'ipscorp') {
          // https://visionfx.atlassian.net/browse/IPS-147
          // 17801 USD/KRW NDF NDMAR
          if (instrumentId === 17801) {
            priceTmp[0] = `${integer}`;
            priceTmp[1] = '';
            priceTmp[2] = '';
            break;
          }
          priceTmp[0] = priceString.substring(0, indexPoint - 1);
          priceTmp[1] = priceString.substring(indexPoint - 1, indexPoint + 2);

          // visionfx.atlassian.net/browse/IPS-148
          priceTmp[2] = `${arrayDecimals1}`;
        } else {
          const [priceTmp0 = '', priceTmp1 = '', priceTmp2 = ''] =
            splitPriceCOP(priceString);
          priceTmp[0] = `${priceTmp0}${priceTmp1}.`;
          priceTmp[1] = priceTmp2.substring(1);
          priceTmp[2] = '';
        }

        break;
      }
      case 'XAU/USD':
        priceTmp[0] = `${integer}.`;
        priceTmp[1] = `${arrayDecimals0}${arrayDecimals1}`;
        arrayDecimals = arrayDecimals.slice(2);
        appliedRule = true;
        break;
      case 'USD/COP':
        priceTmp[0] = `${integer}.`;
        priceTmp[1] = `${arrayDecimals0}${arrayDecimals1}`;
        priceTmp[2] = `${arrayDecimals2}${arrayDecimals3}`;
        break;
      default:
        priceTmp = splitPrice(priceString);
    }

    if (appliedRule) {
      switch (symbol) {
        case 'USD/TWD':
          arrayDecimals = [];
          break;
        case 'USD/INR':
        case 'USD/PHP':
          arrayDecimals = roundUp
            ? roundUpNumber(arrayDecimals, 1)
            : [arrayDecimals[0]];
          break;
        default:
          break;
      }
      priceTmp[2] = arrayDecimals.join('');
    }
  } else {
    priceTmp = splitPrice(priceString);
  }

  return priceTmp;
}

/**
 * Validates the number of decimals allowed on the quantity inputs
 * @param {object} e - input event
 * @param {string} symbol - symbol name for extra validation
 */
export function validNumberDecimals(
  e: React.ChangeEvent<HTMLInputElement>,
  symbol: string
): boolean {
  const {
    target: { value },
  } = e;
  const mount = value.replace(/,/g, '');

  const valid = (valueChecked: string, count: number): boolean => {
    if (
      !Number.isNaN(valueChecked) &&
      valueChecked.toString().indexOf('.') !== -1
    ) {
      if (decimalPlaces(valueChecked) > count) return false;
      return true;
    }
    return true;
  };

  if (symbol === 'USD/JPY') {
    return valid(mount, 0);
  }

  return valid(mount, 2);
}

/**
 * Returns the integer and decimals
 * @param {Number} amount
 */
export function getIntegerDecimals(newAmount: string): [string, string] {
  if (newAmount.includes('.')) {
    const [integerAmount, decimalAmount] = newAmount.split('.');
    return [integerAmount, `.${decimalAmount}`];
  }
  return [newAmount, ''];
}

/**
 * Returns the resulting string from amount input
 * @param {Event} inputEvent
 */
export function handleAmountFromInput(
  inputEvent: React.ChangeEvent<HTMLInputElement>,
  oldRawAmount: string
): string {
  inputEvent.persist();
  let newVal = inputEvent.target.value;

  const oldAmount = oldRawAmount.replace(/,/g, '');
  if (oldAmount === '0') {
    if (newVal[0] === '0') newVal = newVal.slice(1);
    else newVal = newVal.slice(0, -1);
  } else if (newVal === '.') {
    newVal = '0.';
  }
  let newAmount = newVal.replace(/,/g, '');
  const hasLastPoint =
    newAmount[newAmount.length - 1] === '.' && newAmount.split('.').length <= 2;

  newAmount = formatNumberAbrToNumber(newAmount, oldAmount);
  const [newAmountInteger, decimals] = getIntegerDecimals(newAmount);
  newAmount = newAmountInteger;
  const newAmmountFormattedWithComas =
    formatNumberToCommas(newAmount) + (hasLastPoint ? '.' : decimals);

  return newAmmountFormattedWithComas;
}

/**
 *
 * @param {Number | String}
 * @returns {Number | String}
 * 1,000 to 1000
 */
export const removeCommas = (numStr: string): string =>
  (numStr || '').replace(/,/g, '');

/**
 *
 * @param {Array<String>} value
 * @param {Number} exp - exponential
 * @returns {Array<String>}
 */
function roundUpNumber(value: string[], exp: number): string[] {
  const val = Number(value.join(''));

  return Math.ceil(val / 10 ** exp)
    .toString()
    .split('');
}

export const decimalStep = (places: number = 5) => {
  if (places < 1) {
    return '1'; // If no decimal places, step is 1
  }
  return `0.${Array(places).join('0')}1`;
};
