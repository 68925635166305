import React, { useCallback } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Notification from './Notification';

type Notification = {
  id: string;
  type: string;
  title: string;
  timeOut: number;
  message: string;
  onClick: () => void;
  closeButton: boolean;
};

type NotificationsProps = {
  notifications: Notification[];
  enterTimeout: number;
  leaveTimeout: number;
  onRequestHide: (id: string) => void;
};

const Notifications = ({
  notifications = [],
  enterTimeout = 400,
  leaveTimeout = 400,
  onRequestHide,
}: NotificationsProps) => {
  const handleRequestHide = useCallback(
    (id) => () => {
      if (onRequestHide) {
        onRequestHide(id);
      }
    },
    [onRequestHide]
  );

  return (
    <div
      data-testid="notification-container"
      className="notification-container"
    >
      <TransitionGroup>
        {notifications.map((notification) => (
          <CSSTransition
            key={notification.id}
            classNames="notification"
            timeout={{ enter: enterTimeout, exit: leaveTimeout }}
          >
            <Notification
              type={notification.type}
              title={notification.title}
              message={notification.message}
              timeOut={notification.timeOut}
              onClick={notification.onClick}
              onRequestHide={handleRequestHide(notification.id)}
              closeButton={notification.closeButton}
            />
          </CSSTransition>
        ))}
      </TransitionGroup>
    </div>
  );
};

export default Notifications;
