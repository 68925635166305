import React from 'react';

const Loading = () => (
  <div id="dashboard-loading">
    <span className="spinner" />
  </div>
);

export const LoadingButton = ({ cClass = '' }: { cClass: string }) => (
  <button aria-label="loading" type="button" className={cClass}>
    <i className="fas fa-spinner" />
  </button>
);

export default Loading;
